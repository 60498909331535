<template>
  <div>
    <section class="mb-4 d-flex align-center">
      <h3 class="text-h5 text-uppercase font-weight-extrabold">
        Transaction History
      </h3>
      <v-spacer />
      <v-text-field
        v-model="filters.steamid"
        solo dense hide-details flat
        class="rounded-0 text-caption mr-2"
        placeholder="Search by steamid"
        background-color="grey800"
      />
      <v-btn
        color="grey300"
        tile small class="unwidth"
        :ripple="false" :loading="loading"
        @click="fetchApiData"
      >
        <fai :icon="['fas','sync']" class="grey100--text" />
      </v-btn>
    </section>

    <v-expansion-panels
      tag="section" class="mb-4 grey800" accordion flat
      tile
    >
      <v-expansion-panel>
        <v-expansion-panel-header color="grey800">
          Filters
        </v-expansion-panel-header>
        <v-expansion-panel-content color="grey800">
          <v-form @submit.prevent="fetchApiData">
            <v-row>
              <v-col cols="12">
                <v-menu
                  v-model="datepicker"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y right min-width="290px"
                >
                  <template #activator="{ on }">
                    <div class="d-flex">
                      <v-btn
                        color="grey600" class="mr-2"
                        tile large :ripple="false"
                        v-on="on"
                      >
                        {{ filters.range[0] }}
                        <span class="text--secondary mx-2">&ndash;</span>
                        {{ filters.range[1] }}
                      </v-btn>

                      <v-btn
                        large color="grey500" tile class="px-3 mr-2 unwidth"
                        @click.stop="setDateRangePreset('day')"
                      >
                        1D
                      </v-btn>
                      <v-btn
                        large color="grey500" tile class="px-3 mr-2 unwidth"
                        @click.stop="setDateRangePreset('week')"
                      >
                        7D
                      </v-btn>
                      <v-btn
                        large color="grey500" tile class="px-3 mr-2 unwidth"
                        @click.stop="setDateRangePreset('month')"
                      >
                        30D
                      </v-btn>
                      <v-btn
                        large color="grey500" tile class="px-3 mr-2 unwidth"
                        @click.stop="setDateRangePreset('6months')"
                      >
                        6M
                      </v-btn>
                      <v-btn
                        large color="grey500" tile class="px-3 unwidth"
                        @click.stop="setDateRangePreset('year')"
                      >
                        12M
                      </v-btn>
                    </div>
                  </template>
                  <v-date-picker
                    v-model="filters.range"
                    color="primary500"
                    scrollable
                    no-title
                    show-adjacent-months
                    range
                  />
                </v-menu>
              </v-col>

              <v-col cols="12" md="4">
                <v-combobox
                  v-model="filters.types"
                  :items="TX_TYPES"
                  solo flat small-chips multiple
                  clearable
                  class="rounded-0"
                  background-color="grey600"
                  placeholder="You can add custom ones"
                  label="Types"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-combobox
                  v-model="filters.methods"
                  :items="TX_METHODS"
                  solo flat small-chips multiple
                  clearable
                  class="rounded-0"
                  background-color="grey600"
                  placeholder="You can add custom ones"
                  label="Methods"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-autocomplete
                  v-model="filters.statuses"
                  :items="TX_STATUSES"
                  solo flat small-chips multiple
                  clearable
                  class="rounded-0"
                  background-color="grey600"
                  placeholder="Search statuses"
                  label="Statuses"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <section>
      <v-data-table
        class="rounded-0 data-table"
        :headers="headers"
        :items="entries"
        :options.sync="options"
        :footer-props="{itemsPerPageOptions: [5, 10, 25]}"
        :server-items-length="total"
        :expanded="expanded"
        :loading="loading"
        @pagination="onPagination"
      >
        <template #item.type="{ item, value }">
          <!-- TODO super dirty move to comp -->
          <div class="d-flex align-center font-weight-bold">
            <span v-if="item.cryptoCode" class="text-uppercase mr-1 d-inline-flex align-center">
              <cryptoicon :symbol="item.cryptoCode" size="1em" class="mr-1 mb-1" />
              {{ item.cryptoCode }}
            </span>
            <span class="text-capitalize blue200--text ">{{ item.title }}</span>
          </div>
          <div class="text-caption blue400--text lh-1" v-text="item.userId" />
        </template>
        <template #item.createdAt="{ value }">
          <v-tooltip top color="primary500">
            <template #activator="{on}">
              <span v-on="on">{{ value | relativeTime('twitter') }}</span>
            </template>
            <span>{{ value | toDateFormat('YYYY-MM-DD HH:mm:ss') }}</span>
          </v-tooltip>
        </template>
        <template #item.status="{ item, value }">
          <span v-if="+value === TX_STATES.pending">
            <fai

              :icon="['fad', 'sync']"
              spin class="blue400--text mr-2"
            />
            <span class="text-capitalize blue300--text">Pending</span>
          </span>
          <span
            v-else-if="+value === TX_STATES.cancelled"
            class="text-capitalize text--disabled"
            v-text="STATES_TX[value]"
          />
          <span
            v-else-if="+value === TX_STATES.failure"
            class="text-capitalize primary400--text"
            v-text="STATES_TX[value]"
          />
          <span v-else class="text-capitalize blue300--text">{{ STATES_TX[value] || 'Complete' }}</span>
        </template>
        <template #item.amount="{ value, item }">
          <span v-if="!value" class="text--disabled">&mdash;</span>
          <span
            v-else :class="{
              'faded-5': +item.status === TX_STATES.failure || +item.status === TX_STATES.cancelled
            }"
            class="mr-2"
          >
            <span v-if="['withdraw', 'deposit', 'swap'].includes(item.type)">${{ Math.abs(value) | toScrap }}</span>
            <scrap v-else :amount="Math.abs(value)" />
          </span>

          <span v-if="value && +item.status !== TX_STATES.failure && +item.status !== TX_STATES.cancelled">
            <fai
              v-if="item.trend === 'up' && item.type === 'affiliate-kickback'"
              :icon="['fas', 'angle-up']"
              class="blue400--text"
            />
            <fai v-else-if="item.trend === 'up'" :icon="['fas', 'angle-up']" class="green500--text" />
            <fai v-else-if="item.trend === 'down'" :icon="['fas', 'angle-down']" class="primary500--text" />
          </span>
        </template>
        <template #item.actions="{ item }">
          <fai
            :icon="['fad', 'info-square']"
            class="text-body-1 mt-1 blue500--text link"
            @click="onExpandItem(item)"
          />
        </template>

        <!-- transaction info -->
        <template #expanded-item="{ headers: head, item }">
          <td :colspan="head.length" class="grey800 py-3 transaction-detail-container">
            <TransactionDetail
              class="mb-4" :transaction="item" :itemdb="itemdb" :user="user"
              @action="onTxAction"
            />

            <v-expansion-panels class="mb-4" accordion flat tile>
              <v-expansion-panel>
                <v-expansion-panel-header color="grey700">
                  Full transaction object
                </v-expansion-panel-header>
                <v-expansion-panel-content color="grey700">
                  <pre v-text="item" />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </td>
        </template>
      </v-data-table>
    </section>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { CRYPTO_CODES, TX_STATES } from '@/utils/constants'
import { debounce, invertObject } from '@/utils'
import { mapGetters } from 'vuex'
import TransactionDetail from '@/components/transactions/TransactionDetail'

const STATES_TX = invertObject(TX_STATES)

const METHODS = ['daily-case', 'affiliate-reward', 'leaderboard', 'giftcard', 'rust', 'crypto', 'faucet', 'level-case', 'reward', 'skinsback', 'cash', 'offerwall', 'giftcode']
const TYPES = ['buy', 'sell', 'swap', 'promo', 'support', 'deposit', 'withdraw', 'affiliate-kickback', 'affiliate-cashout']

const TX_TITLES = {
  withdraw: {
    rust: 'Rust withdraw',
    crypto: 'Crypto Withdraw',
  },
  deposit: {
    skinsback: 'Skinsback Deposit',
    rust: 'Rust deposit',
    crypto: 'Crypto Deposit',
    offerwall: 'Offers',
    giftcard: 'Giftcard',
    cash: 'Cash deposit',
  },
  promo: {
    'daily-case': 'Daily case',
    'affiliate-reward': 'Affiliate reward',
    giftcode: 'Promo code',
    leaderboard: 'Leaderboard',
    faucet: 'Faucet',
    'level-case': 'Level case',
  },
}

const TX_TRENDS = {
  withdraw: 'down',
  buy: 'down',
  support: '', // depends on val
  swap: 'none',
  deposit: 'up',
  sell: 'up',
  faucet: 'up',
}

export default {
  components: { TransactionDetail },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    payload: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      total: 0,
      expanded: [],
      datepicker: null,
      filters: {
        steamid: '',
        types: [],
        methods: [],
        statuses: [],
        range: [
          DateTime.now().minus({ days: 7 }).toISODate(),
          DateTime.now().toISODate(),
        ],
      },
      options: {
        sortBy: ['createdAt'],
        sortDesc: [true],
        itemsPerPage: 10,
        mustSort: true,
      },
      headers: [
        { text: 'Type & User', value: 'type' },
        {
          text: 'Status', value: 'status', sortable: false,
        },
        { text: 'Amount', value: 'amount', align: 'end' },
        { text: 'Time', value: 'createdAt', align: 'end' },
        { text: '', value: 'actions', width: 8 },
      ],
      entries: [],
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/session',
      itemdb: 'items/items',
    }),
    TX_STATES() {
      return TX_STATES
    },
    STATES_TX() {
      return STATES_TX
    },
    TX_TYPES() {
      return TYPES
    },
    TX_METHODS() {
      return METHODS
    },
    TX_STATUSES() {
      return Object.entries(TX_STATES).map(([text, value]) => ({ text, value }))
    },
  },
  watch: {
    options: {
      handler() {
        this.fetchApiData()
      },
      deep: true,
    },
    filters: {
      handler() {
        this.fetchApiData()
      },
      deep: true,
    },
    payload: {
      handler(payload) {
        if (payload?.steamid) {
          this.filters.steamid = payload.steamid
        }
      },
      immediate: true,
    },
  },
  methods: {
    fetchApiData: debounce(async function fetchApiData() {
      this.loading = true

      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options

      try {
        const { entries, total } = await this.$socket.request('admin.user.transactions', {
          page,
          sortBy: sortBy[0],
          asc: !sortDesc[0],
          limit: itemsPerPage,
          filter: {
            ...this.filters,
            dateStart: this.filters.range[0],
            dateEnd: this.filters.range[1],
          },
        })

        this.entries = entries.map(i => {
          let trend = TX_TRENDS[i.type]

          if (!trend) {
            trend = i.amount > 0 ? 'up' : 'down'
          }

          return {
            ...i,
            title: TX_TITLES[i.type]?.[i.method] ?? i.type,
            cryptoCode: CRYPTO_CODES[i.data?.currency],
            trend,
          }
        })
        this.total = total
      } catch (error) {
        this.entries = []
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    }, 500),
    onExpandItem(item) {
      const [expandedItem] = this.expanded

      if (item.id === expandedItem?.id) {
        this.expanded = []
      } else {
        this.expanded = [item]
      }
    },
    onPagination() {
      this.expanded = []
    },
    setDateRangePreset(preset) {
      switch (preset) {
        case 'day':
          this.filters.range = [DateTime.now().toISODate(), DateTime.now().toISODate()]
          break
        case 'week':
          this.filters.range = [DateTime.now().minus({ days: 7 }).toISODate(), DateTime.now().toISODate()]
          break
        case 'month':
          this.filters.range = [DateTime.now().minus({ months: 1 }).toISODate(), DateTime.now().toISODate()]
          break
        case '6months':
          this.filters.range = [DateTime.now().minus({ months: 6 }).toISODate(), DateTime.now().toISODate()]
          break
        case 'year':
          this.filters.range = [DateTime.now().minus({ years: 1 }).toISODate(), DateTime.now().toISODate()]
          break
        default:
          break
      }
    },
    async onTxAction({ transaction, action }) {
      if (action === 'credit') {
        this.loading = true

        try {
          await this.$socket.request('admin.user.txs.unlockOfferwallDeposit', transaction._id)

          this.fetchApiData()
          this.$toast.success('Offer deposit credited')
        } catch (error) {
          this.$toast.error(error.message)
        } finally {
          this.loading = false
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.data-table ::v-deep {
  background-color: grey(600);

  .v-data-table-header {
    background-color: grey(500);
  }

  .v-data-footer {
    background-color: grey(500);
  }

  .v-data-table__expanded__row {
    td {
      border-bottom: 0 !important;
    }
  }

  .transaction-detail-container {

  }
}

.pagination ::v-deep {
  .v-pagination__item,
  .v-pagination__navigation {
    border-radius: 0;
    background-color: grey(500);
    box-shadow: none;
  }
}
</style>
