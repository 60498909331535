<template>
  <v-container fluid class="px-2">
    <v-row class="grey700">
      <v-col cols="12" md="6">
        <h5 v-t="'page.account.transactions.detail.id'" class="primary400--text text-body-2" />
        <p class="mb-0 text--secondary lh-1-2 text-caption" v-text="transaction.id" />
      </v-col>
      <v-col cols="12" md="6" class="text-right">
        <h5 v-t="'common.date_created'" class="primary400--text text-body-2" />
        <p class="mb-0 text--secondary lh-1-2 text-caption">
          {{ transaction.createdAt | toDateFormat('YYYY-MM-DD HH:mm:ss') }}
        </p>
      </v-col>
    </v-row>

    <component
      :is="detailComponent"
      class="mt-4"
      :transaction="transaction"
      :itemdb="itemdb"
      :user="user"
      @action="$emit('action', { transaction, action: $event })"
    />
  </v-container>
</template>

<script>

const METHOD_COMPONENTS = {
  crypto: () => import('./TransactionDetailCrypto'),
  rust: () => import('./TransactionDetailRust'),
  skinsback: () => import('./TransactionDetailSkinsback'),
  offerwall: () => import('./TransactionDetailOffers'),
  giftcard: () => import('./TransactionDetailGiftcard'),
  cash: () => import('./TransactionDetailCash'),
  reward: () => import('./TransactionDetailPromoReward'),
  'daily-case': () => import('./TransactionDetailPromoDailyCase'),
  'level-case': () => import('./TransactionDetailPromoLevelCase'),
  'affiliate-reward': () => import('./TransactionDetailPromoAffReward'),
}

const TYPE_COMPONENTS = {
  swap: () => import('./TransactionDetailSwap'),
  sell: () => import('./TransactionDetailShop'),
  buy: () => import('./TransactionDetailShop'),
  support: () => import('./TransactionDetailSupport'),
  leaderboard: () => import('./TransactionDetailLeaderboard'),
  giftcode: () => import('./TransactionDetailGiftcode'),
}

export default {
  props: {
    transaction: {
      type: Object,
      required: true,
    },
    itemdb: {
      type: Object,
      required: true,
    },
    user: {
      type: Object,
      default: null,
    },
  },
  computed: {
    detailComponent() {
      const { type, method } = this.transaction
      return METHOD_COMPONENTS[method] || TYPE_COMPONENTS[type]
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
